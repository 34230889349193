<template>
  <div class="wash-services">
    <Header
      :isHeader="true"
      :back="true"
      :carInfo="true"
      :mobileResume="true"
      :dealer="true"
      :services="true"
      :mobility="true"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar
          :desktopResume="true"
          :dealer="true"
          :services="true"
          :mobility="true"
        />
      </template>
      <Title titleStyle="title" :text="translateString('washServices')" />
      <PopUpWashServices
        v-if="showPopNoWashServices"
        :text="
          translateString('noWashServiceSelected') + translateString('vehicle')
        "
        :question="translateString('wantToProceedWash')"
        :firstBtn="translateString('addServices')"
        @action="actionPopUp()"
      ></PopUpWashServices>
      <br />
      <Card v-if="groupedServices.length === 0">
        <p class="sm-text">{{ translateString("noWashServicesAvailable") }}</p>
      </Card>
      <Card
        v-else
        v-for="(group, index) in groupedServices.filter((el) => el.length > 0)"
        :key="index"
      >
        <CheckboxContainer
          v-for="(service, index) in group"
          :key="index"
          checkboxClass="checkbox-round radio-checkbox "
          containerClass="left"
          inputType="checkbox"
          :id="'service-' + service.id"
          :value="service.name"
          :name="'group-' + service.groupID"
          :isChecked="servicesIDSelected.includes(Number(service.id)) || (defaultWashOption && service.id === defaultWashOption.id)"

          @updateInput="updateInput($event)"
        >
          <LabelComplex :forLabel="'service-' + service.id">
            <div class="row">
              <Badge
                v-if="servicesWithPromo.includes(Number(service.id))"
                label="Promo"
                position="relative "
              />
              <div>
                <p class="sm-text">
                  {{ service.name }}
                </p>
              </div>
            </div>
            <div
              v-if="servicesWithPromo.includes(Number(service.id))"
              class="price-container"
            >
              <p class="text old-price">
                {{ formatPrice(service.price, 2, "€") }}
              </p>
              <p class="text price">
                {{ formatPrice(service.price_Desc, 2, "€") }}
              </p>
            </div>
            <div v-else class="price-container">
              <p class="text price">
                {{ formatPrice(service.price, 2, "€") }}
              </p>
            </div>
            <br />
          </LabelComplex>
        </CheckboxContainer>
      </Card>
      <br />
      <template v-slot:footer>
        <Footer>
          <br />
          <br />
          <Button
            id="getMobilityServices"
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            class="btn-primary align-btn-getAvailability"
            @action="next('getMobilityServices')"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Badge from "@/components/Badge/Badge.vue";
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import PopUpWashServices from "../components/PopUp/PopUpWashServices.vue";
import { mapState } from "vuex";

import {
  formatPrice,
  groupServices,
  translateString,
} from "@/functions/functions.js";

export default {
  name: "WashServices",
  components: {
    Badge,
    Button,
    Card,
    CheckboxContainer,
    Footer,
    Header,
    LabelComplex,
    Modal,
    Sidebar,
    Title,
    Wrapper,
    PopUpWashServices,
  },
  data() {
    return {
      defaultWashOption: null,
      ctaBtn:
        this.$store.state.washServiceSelected.length == 0 ? "disabled  " : "",
      services: this.$store.state.washServices,
      groupedServices: groupServices(this.$store.state.washServices, true),

      servicesSelected: [],
      servicesIDSelected: this.$store.state.washServiceIDSelected,
      washTotal: 0,
      washOldTotal: 0,
      servicesTotal: 0,
      popupWarningShow: false,
      noWashServicesAvailable: false,
      servicesWithPromo: [],
      campaignInfo: this.$store.getters.getCampaignInfo,
      hasCampaigns: this.$store.state.hasCampaigns,
      campaignsUsed: this.$store.state.campaignsUsed,
      showPopNoWashServices: false,
      totalServicesManutations: this.$store.state.updatableServicesTotal,
      servicesListSelected: this.$store.state.servicesSelectedCompletedList,
    };
  },
  computed: {
    totalValue() {
      let total = 0;
      this.services.forEach((service) => {
        if (this.servicesIDSelected.includes(Number(service.id))) {
          if (service.price >= 0) {
            total += service.price_Desc ? service.price_Desc : service.price;
          }
        }
      });
      return total;
    },

    finalError() {
      return this.$store.state.finalError;
    },
    servicesReady() {
      return this.$store.state.mobilityServicesReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    total() {
      return this.$store.state.servicesTotal;
    },
    isServiceIdMinusOne() {
      return this.$store.state.isServiceIdMinusOne;
    },
    ...mapState(["markingServiceSelected"]),
  },
  created() {
    if (this.markingServiceSelected && this.markingServiceSelected.lstServicesId) {
      const selectedIds = this.markingServiceSelected.lstServicesId;

      const allServices = this.services;

      // Encontro o primeiro serviço cujo id está na lista selectedIds
      const selectedService = allServices.find(service =>
        selectedIds.includes(String(service.id))
      );

      if (selectedService) {
        // Adiciono o id do selectedService ao array servicesIDSelected
        this.servicesIDSelected.push(selectedService.id);
      } else {
        console.log('No service found with the selected ids');
      }
    }
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
    this.$store.dispatch("clearSelectedInfo", "WashServices");

    if (this.hasCampaigns) this.checkCampaigns();

    if (this.servicesIDSelected.length === 0) {
      this.ctaBtn = "disabled ";
    }
    if (this.groupedServices.length === 0) {
      this.noWashServicesAvailable = true;
      this.ctaBtn = " ";
    }
  },
  methods: {
    formatPrice,
    translateString,
    //Metodo para verificar se existe algum serviço com id e preço iguais a -1 em manutenção quando estiver em washServices e nao mostrar o popup
    checkForServiceWithNegativeValues() {
      let foundNegativeService = false;

      // iterarando sobre o array de serviços
      this.$store.state.servicesSelectedCompletedList.forEach(
        (serviceGroup) => {
          serviceGroup.services.forEach((service) => {
            // Verificando se o id e o preço do serviço são -1
            if (
              (service.id === -1 && service.price === -1) ||
              (service.id === -1 && service.price === 5)
            ) {
              // Se o serviço tiver id e preço iguais a -1, defino a variável foundNegativeService como true
              foundNegativeService = true;
            }
          });
        }
      );
      // Verifico se algum serviço com id e preço iguais a -1 foi encontrado
      if (foundNegativeService) {
        this.inspectionServices = true;
      } else {
        this.inspectionServices = false;
      }
    },

    checkCampaigns() {
      let addServices = this.servicesIDSelected.length == 0 ? true : false;

      this.services.filter((service) => {
        if (service.campaigns != null && service.campaigns.length > 0) {
          this.servicesWithPromo.push(Number(service.id));
          service["price_Desc"] = service.campaigns[0].price_Desc;
          if (addServices) {
            if (service.campaigns[0].campaign_id == this.campaignInfo.uuid) {
              this.servicesIDSelected.push(Number(service.id));
            }
          }
        }
      });
    },
    recalculateTotal() {
      this.washTotal = 0;
      this.washOldTotal = 0;
      this.services.forEach((service) => {
        if (this.servicesIDSelected.includes(Number(service.id))) {
          this.washTotal += service.price_Desc
            ? service.price_Desc
            : service.price;
          this.washOldTotal += service.price;
        }
      });
    },
    updateInput(e) {
      const id = Number(e.id.split("-")[1]);
      if (e.checked) {
        if (!this.servicesIDSelected.includes(id))
          this.servicesIDSelected.push(id);
      } else {
        if (this.servicesIDSelected.indexOf(id) > -1) {
          this.servicesIDSelected.splice(
            this.servicesIDSelected.indexOf(id),
            1
          );
        }
      }
      // Atualizar o valor no Vuex store
      this.$store.commit("updateWashServicesTotal", this.washTotal);
      this.$store.dispatch("selectOption", {
        selectedValue: "washServicesTotal",
        value: this.washTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "washServiceIDSelected",
        value: this.servicesIDSelected,
      });

      this.recalculateTotal();
    },

    actionPopUp() {
      // Limpar a lista de IDs de serviços selecionados
      this.servicesIDSelected = [];

      // Limpar a lista de serviços selecionados
      this.servicesSelected = [];

      this.showPopNoWashServices = false;
      this.$store.commit("RESET_WASH_TOTALS");
    },
    saveAllInfo() {
      this.washTotal = 0;
      this.washOldTotal = 0;
      this.services.forEach((service) => {
        if (this.servicesIDSelected.includes(Number(service.id))) {
          this.servicesSelected.push({
            id: service.id,
            name: service.name,
            price: service.price_Desc ? service.price_Desc : service.price,
          });
          this.washTotal += service.price_Desc
            ? service.price_Desc
            : service.price;
          this.washOldTotal += service.price;
        }

        // Listar as campanhas utilizadas
        if (service.campaigns != null && service.campaigns.length > 0) {
          const campaign = service.campaigns.find((el) => {
            return el.price_Desc == service.price_Desc;
          });

          if (campaign != null) {
            if (!this.campaignsUsed.includes(campaign.campaign_id)) {
              this.campaignsUsed.push(campaign.campaign_id);
            }
          }
        }
      });

      this.servicesTotal = this.totalValue;
      this.$store.commit("updateWashServicesTotal", this.washTotal); // Atualizar o valor no Vuex store

      // condição criada especificamente para verificar quando mando dispatch com servicesTotal ou this.total + this.servicesTotal,
      // afim de nao quebrar o valor total dos serviços no updated Resume .vue
      if (this.washTotal === -2 && this.servicesTotal === 0) {
        this.$store.dispatch("selectOption", {
          selectedValue: "servicesTotal",
          value: this.total + this.servicesTotal,
        });
      } else {
        this.$store.dispatch("selectOption", {
          selectedValue: "servicesTotal",
          value: this.total + this.servicesTotal,
        });
      }
      this.$store.dispatch("selectOption", {
        selectedValue: "washServiceIDSelected",
        value: this.servicesIDSelected,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "washServiceSelected",
        value: this.servicesSelected,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "washServicesTotal",
        value: this.washTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "washServicesOldTotal",
        value: this.washOldTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "campaignsUsed",
        value: this.campaignsUsed,
      });
      /*  if (this.total >= 1 && this.total <= 20) {
        return (this.showPopNoWashServices = true);
      } else {
        return (this.showPopNoWashServices = false);
      } */
    },

    next(id) {
      this.checkForServiceWithNegativeValues();

      this.saveAllInfo();
      if (this.noWashServicesAvailable) {
        if (this.servicesReady) {
          this.$router.push({ name: "MobilityServices" });
        } else {
          this.$store.dispatch("getMobilityServices");
          this.$store.dispatch("selectOption", {
            selectedValue: "isLoading",
            value: id,
          });
        }
      } else if (
        this.washOldTotal === -1 &&
        this.$store.state.updatableServicesTotal < 20
      ) {
        this.showPopNoWashServices = true;
      } else if (
        (this.washOldTotal === -2 &&
          this.$store.state.updatableServicesTotal === 0) ||
        this.$store.state.updatableServicesTotal >= 20 ||
        (this.$store.state.updatableServicesTotal >= 20 &&
          this.washOldTotal === -2) ||
        (this.$store.state.updatableServicesTotal == 0 &&
          this.washOldTotal >= 0) ||
        (this.inspectionServices == true && this.washOldTotal >= -2)
      ) {
        this.showPopNoWashServices = false;
        this.$store.dispatch("getMobilityServices");
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      } else if ((this.total >= 1 && this.total <= 20) || this.total == 0) {
        this.showPopNoWashServices = true;
      } else if (this.servicesReady) {
        this.$router.push({ name: "MobilityServices" });
      } else {
        this.$store.dispatch("getMobilityServices");
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      }
    },
  },
  watch: {
    servicesIDSelected() {
      if (
        this.groupedServices.length === 0 ||
        this.servicesIDSelected.length > 0
      ) {
        this.ctaBtn = "";
      } else {
        this.ctaBtn = "disabled ";
      }
    },
    servicesReady() {
      if (this.servicesReady == true) {
        this.$router.push({ name: "MobilityServices" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
